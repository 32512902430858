import React from "react"
import Layout from "../components/layout"

const TurtlesPage = () => (
    <Layout>
        <h1>turtles cam</h1>
        <img src="http://side1.iot-smarwag.de:49494/" alt="turtles cam" style={{ height: '100vh', width: '100%', objectFit: 'contain' }} />
    </Layout>
)

export default TurtlesPage
